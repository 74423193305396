
import { css } from "@emotion/css";
import { Button } from "@mui/material";
import Component from "@xavisoft/react-component";
import { Link } from '@xavisoft/app-wrapper';
import CloseIcon from '@mui/icons-material/Close';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { logout } from "../utils";


const sideNavCss = css({
   color: 'white',
   position: 'fixed',
   top: 0,
   left: 0,
   right: 0,
   bottom: 0,

   '& a': {
      color: 'white',
      // textUnderlineOffset: '3px',
      textDecoration: 'none',
      fontSize: '16pt',
      margin: '12pt auto',
      textTransform: 'uppercase',
   }
})

class MobileNav extends Component {

   render() {

      const sideNavStyle = {
         display: this.props.open ? 'flex' : 'none',
      };

      const links = this.props.links || []

      return <div style={sideNavStyle} className={`${sideNavCss} valign primary-bg`}>
         <div className="center-align" style={{ width: '100%'}}>
            {
               links.map(link => {
                  return <Link
                     key={link.to}
                     to={link.to}
                     className="v-align px-8"
                     onClick={() => {
                        this.props.close();

                        if (typeof link.onClick === 'function')
                           link.onClick();
                           
                     }}
                  >
                     <ChevronRightIcon className="mr-2" />
                     {link.caption}
                  </Link>
               })
            }
         </div>

         <Button
            style={{
               position: 'absolute',
               top: '24pt',
               right: '24pt',
               color: 'white',
            }}
            onClick={this.props.close}
         >
            <CloseIcon />
         </Button>

         {
            this.props.logoutButton ?
               <div className="text-center p-8 absolute bottom-0 right-0 left-0">
                  <Button 
                     className="bg-white text-purple-600 rounded-3xl px-6" 
                     variant="contained"
                     onClick={
                        () => {
                           logout();
                           this.props.close();
                        }
                     }
                  >
                     LOGOUT
                  </Button>
               </div> : undefined
         }
      </div>
   }
}


export default MobileNav;