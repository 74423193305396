

import { ACTION_TYPES } from "../constants";


export default function authenticatedReducer(state=null, action) {

   if (action.type === ACTION_TYPES.SET_ROOT_CERTIFICATE)
      return action.payload;

   return state;
}