import Component from "@xavisoft/react-component";
import AppBar from '@mui/material/AppBar';
import {  Button, Grid, Hidden } from "@mui/material";
import { Link } from '@xavisoft/app-wrapper';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MobileNav from "./MobileNav";
import { connect } from "react-redux";
import { logout } from "../utils";



const signupAndLoginLinks = [
   {
      caption: 'Login',
      to: '/login',
   },
   {
      caption: 'Sign Up',
      to: '/signup',
   },
]



class NavbarUnconnected extends Component {


   state = {
      mobileNavOpen: false,
   }

   openMobileNav = () => {
      return this.updateState({ mobileNavOpen: true })
   }

   closeMobileNav = () => {
      return this.updateState({ mobileNavOpen: false })
   }

   setDimensions = () => {
      const navbar = document.getElementById('navbar');
      
      const width = navbar.scrollWidth + 'px';
      const height = navbar.scrollHeight + 'px';

      document.documentElement.style.setProperty('--navbar-width', width);
      document.documentElement.style.setProperty('--navbar-height', height);
   }

   componentWillUnmount() {
      this.resizeOberver.disconnect();
   }

   componentDidMount() {
      const resizeOberver = new window.ResizeObserver(this.setDimensions);
      resizeOberver.observe(document.getElementById('navbar'));
      this.resizeOberver = resizeOberver;

      this.setDimensions();
   }

   
   render() {

      const currentRoute = this.props.currentRoute || '/';
      let jsx;

      if (currentRoute === '/dashboard') {
         jsx = <></>
      } else {

         const links = [
            {
               caption: 'Home',
               to: '/'
            },
            {
               caption: 'Documentation',
               to: '/documentation',
            },
         ];

         if (!window.cordova) {
            links.push({
               caption: 'Downloads',
               to: '/downloads',
            });
         }

         let actionButtonJSX;

         if (this.props.authenticated) {
            const dashboardLink = {
               caption: 'Dashboard',
               to: '/dashboard',
            }

            links.push(dashboardLink);
            actionButtonJSX = <Button onClick={logout}>
               LOGOUT
            </Button>

         } else {
            links.push(...signupAndLoginLinks);
            actionButtonJSX = <Button 
               variant="contained"
               size="large"
               className="rounded-3xl bg-purple-700"
            >
               GET STARTED
            </Button>
         }

         jsx = <Grid container>

         <Grid item xs={8} sm={6} md={3}>
            <Link 
               to="/"
               className="valign no-underline"
            >
              <h2 className="font-extrabold text-3xl text-purple-800 m-4">setget</h2> 
            </Link>
         </Grid>


         <Hidden only={[ 'xs', 'sm' ]}>
            <Grid item md={9}>
               <div
                  style={{
                     gridTemplateColumns: '1fr auto',
                  }}
                  className="h-full w-full grid"
               >
                  <div className="v-align justify-end">
                     {
                        links.map(link => {

                           const color = link.to === currentRoute ? 'black' : 'grey';

                           return <Link
                              style={{ color, }}
                              className="inline-block no-underline m-[12px] text-[11pt] uppercase"
                              to={link.to}
                              onClick={link.onClick}
                              key={link.caption}
                           >{link.caption}</Link>
                        })
                     }
                  </div>
                  
                  <div className="v-align p-[8pt]">
                     {actionButtonJSX}
                  </div>
               </div>
                  
            </Grid>
         </Hidden>

         <Hidden only={[ 'md', 'lg', 'xl' ]}>
            <Grid item xs={4}>
               <div
                  className="w-full h-full flex justify-center content-center"
               >
                  <Button onClick={this.openMobileNav}>
                     <MenuOpenIcon />
                  </Button>

                  <MobileNav
                     open={this.state.mobileNavOpen}
                     close={this.closeMobileNav}
                     links={links}
                     logoutButton={this.props.authenticated}
                  />
               </div>
            </Grid>
         </Hidden>
      </Grid>
      }

      return <AppBar id="navbar" className='bg-gradient-to-br from-white to-purple-300'>
         {jsx}
      </AppBar>
   }
}



function mapStateToProps(state) {
   const { authenticated, currentRoute } = state;
   return { authenticated, currentRoute }
}

const Navbar = connect(mapStateToProps)(NavbarUnconnected);
export default Navbar;