
import { schema } from 'normalizr';


// actuators
export const Actuator = new schema.Entity('actuators');

// webhooks
export const Webhook = new schema.Entity('webhooks');

// trigger actuator
export const TriggerActuator = new schema.Entity('trigger_actuators', {
   actuator: Actuator,
});

// triggers
export const Trigger = new schema.Entity('triggers', {
   webhooks: [ Webhook ],
   actuators: [ TriggerActuator ]
});

// sensors
export const Sensor = new schema.Entity('sensors', {
   triggers: [ Trigger ]
});


// device
export const Device = new schema.Entity('devices', {
   sensors: [ Sensor ],
   actuators: [ Actuator ]
});


export const DeviceList = [Device];

export const schemaList = [
   Actuator,
   Device,
   Sensor,
   Trigger,
   TriggerActuator,
   Webhook,
]


export class EntityUpdate {

   _filters = {}
   _pushes = {}
   _setters = {}

   /**
    * 
    * @param {string} attr 
    * @param {function} func 
    */
   addArrayFilter(attr, func) {

      if (typeof func !== 'function')
         throw new Error('"func" should be a function')

      this._filters[attr] = func;
   }

   /**
    * 
    * @param {string} attr 
    * @param {any} value 
    */
   addToArray(attr, value) {
      this._pushes[attr] = value;
   }

   /**
    * 
    * @param {string} attr 
    * @param {any} value 
    */
   setAttribute(attr, value) {
      this._setters[attr] = value;
   }

   getArrayPushes() {
      return this._pushes;
   }

   getSetAttributes() {
      return this._setters;
   }

   getArrayFilters() {
      return this._filters;
   }

}