
import './App.css';
import AppWrapper, { Route } from '@xavisoft/app-wrapper';
import Navbar from './components/Navbar';
import { Provider } from 'react-redux';
import store from './store';
import Footer from './components/Footer';
import SensorHistory from './components/SensorHistory';
import Component from '@xavisoft/react-component';
import ErrorBoundary from './components/ErrorBoundary';
import SuspensFallback from './components/SuspenseFallback';
import { Suspense, lazy } from 'react';

const Dashboard = lazy(() => import('./pages/Dashboard'));
const Documentation = lazy(() => import('./pages/Documentation'));
const Downloads = lazy(() => import('./pages/Downloads'));
const Home = lazy(() => import('./pages/Home'));
const Login = lazy(() => import('./pages/Login'));
const PageNotFound = lazy(() => import('./pages/PageNotFound'));
const Signup = lazy(() => import('./pages/Signup'));
const Test = lazy(() => import('./pages/Test'));
const VerifyAccount = lazy(() => import('./pages/VerifyAccount'));


Component.prototype.updateState = function (updates={}) {
	return new Promise(resolve => {
		const state = this.state || {};
		const newState = { ...state, ...updates }
		this.setState(newState, resolve);
	})
}

function setDimensions() {
	
	const width = window.innerWidth + 'px';
	const height = window.innerHeight + 'px';

	document.documentElement.style.setProperty('--window-width', width);
	document.documentElement.style.setProperty('--window-height', height);

}



window.addEventListener('resize', setDimensions);
setDimensions();

function App() {

	let router;

	if (window.cordova)
		router = 'hash';

	return <ErrorBoundary>
		<Provider store={store}>
			<Suspense fallback={<SuspensFallback />}>
				<AppWrapper router={router}>

					<Navbar />

					<Route path="/" component={Home} />
					<Route path="/signup" component={Signup} />
					<Route path="/verify-account/:ref_code" component={VerifyAccount} />
					<Route path="/login" component={Login} />
					<Route path="/dashboard" component={Dashboard} />
					<Route path="/downloads" component={Downloads} />
					<Route path="/documentation" component={Documentation} />

					<Route path="/test" component={Test} />
					<Route path="*" component={PageNotFound} />

					<Footer />
					<SensorHistory />

				</AppWrapper>
			</Suspense>
		</Provider>
	</ErrorBoundary>
}

export default App;
