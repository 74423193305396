
import * as Sentry from "@sentry/react";

function init() {
   Sentry.init({
      dsn: "https://c930e1fdb13e25b84665428fe596ac9a@o4504122577059840.ingest.sentry.io/4505985941635072",
      integrations: [
         new Sentry.BrowserTracing({}),
         new Sentry.Replay(),
      ],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 1.0,
      replaysOnErrorSampleRate: 1.0,
   });
}

function error(err) {
   Sentry.captureException(err);
}

function log(msg) {
   Sentry.captureMessage(msg);
}

let logger = {
   init,
   error,
   log,
};

if (process.env.NODE_ENV === 'development')
   logger = console;


export default logger;