

const ACTION_TYPES = {
   ADD_TRIGGER: 'add-trigger',
   REMOVE_WEBHOOK: 'remove-webhook',
   SET_AUTHENTICATED: 'set-authenticated',
   SET_CURRENT_ROUTE: 'set-current-route',
   SET_DEVICES: [],
   SET_PREVIOUS_ROUTE: 'set-previous-route',
   SET_ROOT_CERTIFICATE: 'set-root-certificate',
   SET_SENSOR_HISTORY: 'set-sensor-history',
   UPDATE_ACTUATOR_STATE: 'update-actuator-state',
}

const ACTUATOR_TYPES = {
   LIGHT_BULB: 'LIGHT_BULB',
   MOTOR: 'MOTOR',
   BINARY: 'BINARY',
   LINEAR: 'LINEAR',
   LINEAR_BINARY: 'LINEAR_BINARY',
   DISCRETE: 'DISCRETE',
}

const SENSOR_TYPES = {
   TEMPERATURE: 'TEMPERATURE',
   NEW_TYPE: 'NEW_TYPE'
}

const SENSOR_THRESHOLD_TYPES = {
   EXCEEDS: 'EXCEEDS',
   FALLS_BELOW: 'FALLS_BELOW',
}

const DEVICE_PERMISSIONS = {
   READ_ONLY: 'read_only',
   CONTROL: 'control',
}

export  {
   ACTION_TYPES,
   ACTUATOR_TYPES,
   DEVICE_PERMISSIONS,
   SENSOR_THRESHOLD_TYPES,
   SENSOR_TYPES,
}
