
import CircularProgress from '@mui/material/CircularProgress'

export default function SuspenseFallback() {

   return <div className="fixed top-0 h-screen w-screen bg-white vh-align z-[2000]">
      <div className='text-center'>
         
         <CircularProgress />

         <p className="text-lg text-gray-600">
            Please wait...
         </p>
      </div>
   </div>
}