
import { ClickAwayListener } from '@mui/material';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale } from 'chart.js/auto'
import { connect } from 'react-redux';
import actions from '../actions';

ChartJS.register(CategoryScale)


function SensorHistoryUnconnected(props) {

   if (!props.sensorHistory)
      return null;

   const labels = [], values = [];

   props.sensorHistory.forEach(value => {
      labels.push('');
      values.push(value.value);
   });

   labels[0] = props.sensorHistory[0].createdAt;
   labels[labels.length - 1] = props.sensorHistory[labels.length - 1].createdAt;

   return <div className='fixed top-0 left-0 bottom-0 right-0'>
      <div className='h-full w-full vh-align bg-[rgba(0,0,0,0.9)]'>
         <ClickAwayListener onClickAway={() => actions.setSensorHistory(null) }>
            <div className='w-[100%] max-w-[600px] aspect-square bg-white'>
               <Line
                  data={{
                     labels,
                     datasets: [
                        {
                        id: 1,
                        label: '',
                        data: values,
                        }
                     ],
                  }}
               />
            </div>
         </ClickAwayListener>
      </div>
   </div>
} 


function mapStateToProps(state) {
   const { sensorHistory } = state;
   return { sensorHistory }
}

const SensorHistory = connect(mapStateToProps)(SensorHistoryUnconnected);
export default SensorHistory;