import swal from "sweetalert";
import { ACTUATOR_TYPES } from "./constants";
import { LOCAL_STORAGE_KEY } from "@xavisoft/auth/constants";
import actions from './actions';


function getIntrinsicActuatorType(type) {
   
   // group non-intrinsic
   // all other should return argument
   
   switch (type) {
      case ACTUATOR_TYPES.LIGHT_BULB:
      case ACTUATOR_TYPES.MOTOR:
         return ACTUATOR_TYPES.BINARY;
   
      default:
         return type;
   }
}

function actuatorBinaryBooleanToString(booleanValue) {
   return booleanValue ? 'ON' : 'OFF';
}

function actuatorBinaryStringToBoolean(stringValue) {
   stringValue = stringValue.toUpperCase();
   return stringValue === 'ON';
}

function parseActuatorType(_type) {

   let binary, linear, type, discrete;

   try {
      const indexOfOpeningBracket = _type.indexOf('(');
      
      if (indexOfOpeningBracket === -1) {
         type = _type;
         binary = true;
      } else {

         type = _type.substring(0, indexOfOpeningBracket);

         if ([ ACTUATOR_TYPES.LINEAR, ACTUATOR_TYPES.LINEAR_BINARY ].includes(type)) {
            // get range details
            const indexOfClosingBracket = _type.indexOf(')');
            
            if (indexOfClosingBracket === -1) {
               linear = {
                  min: 0,
                  max: 100,
                  step: 1,
               }
            } else {
               
               let [ min, max, step ] = _type.substring(indexOfOpeningBracket + 1, indexOfClosingBracket).split(',');
               min = parseFloat(min) || 0;
               max = parseFloat(max) || 100;
               step = parseFloat(step) || 100;

               linear = {
                  min,
                  max,
                  step,
               }
            }

            if (type === ACTUATOR_TYPES.LINEAR_BINARY)
               binary = true;

         } else if (type === ACTUATOR_TYPES.DISCRETE) {
            const indexOfClosingBracket = _type.indexOf(')');

            if (indexOfClosingBracket === -1) {
               binary = true
            } else {
               const choices = _type
                  .substring(indexOfOpeningBracket + 1, indexOfClosingBracket)
                  .split(',')
                  .map(item => item.trim())

               discrete = choices;

            }
         } else{
            binary = true;
         }
      }

   } catch (err) {
      binary = true;
      type = ACTUATOR_TYPES.BINARY;
   }

   return {
      binary,
      linear,
      type,
      discrete
   }
}

function describeTriggerActuatorAction(actuatorName, actuatorState) {
   return <>
      SET <b>{actuatorName}</b> state to <b>{actuatorState}</b>
   </>
}

function requestConfirmation({
   question,
   confirmButtonCaption='Yes',
   cancelButtonCaption="Cancel",
}) {
   return swal({
      text: question,
      buttons: [
         {
            text: confirmButtonCaption,
            value: true,
            className: "red-bg white-text",
            visible: true,
         },
         {
            text: cancelButtonCaption,
            value: false,
            visible: true,
            className: "white-bg primary-text"
         }
      ]
   });
}

function getAttributeFromElementHierachy(target, attr) {

   let attribute = target.getAttribute(attr);

   while (!attribute) {
      target = target.parentElement;

      if (!target)
         break;

      attribute = target.getAttribute(attr);

   }

   return attribute;
}


function parseActuatorState(state) {

   state = state.trim();

   let value, binary;

   if (state.charAt(0) === '(') {
      const [ binaryString, valueString ] = state
         .substring(1, state.length -  1)
         .split(',')
         .map(item => item.trim());

      value = valueString
      binary = actuatorBinaryStringToBoolean(binaryString);

   } else {
      value = state;
      binary = actuatorBinaryStringToBoolean(value);
   }

   return { value, binary }

}

function deleteAuthTokens() {
   window.localStorage.removeItem(LOCAL_STORAGE_KEY);
}

function copyTextToClipboard(text) {
   return navigator.clipboard.writeText(text)
}

function decodeJWT(token) {
   var base64Url = token.split('.')[1];
   var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
   var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
       return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
   }).join(''));

   return JSON.parse(jsonPayload);
}

function delay(millis) {
   return new Promise(resolve => {
      setTimeout(resolve, millis)
   });
}

function logout() {
   deleteAuthTokens();
   actions.setAutheticated(false);
   window.App.redirect('/login');
}

export {
   actuatorBinaryBooleanToString,
   actuatorBinaryStringToBoolean,
   copyTextToClipboard,
   decodeJWT,
   delay,
   deleteAuthTokens,
   describeTriggerActuatorAction,
   getAttributeFromElementHierachy,
   getIntrinsicActuatorType,
   logout,
   parseActuatorState,
   parseActuatorType,
   requestConfirmation,
}