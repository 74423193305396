import { Link } from "@xavisoft/app-wrapper";
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { Divider } from "@mui/material";
import { connect } from "react-redux";


function Socials() {

   const links = [
      {
         icon: LocalPhoneIcon,
         href: 'tel:263715919598',
      },
      {
         icon: AlternateEmailIcon,
         href: 'mailto:support@setget.com',
      },
      {
         icon: FacebookIcon,
         href: 'https://facebook.com/setget',
      },
      {
         icon: YouTubeIcon,
         href: 'youtube.com/setget',
      },
   ]

   return <div className="vh-align m-4 mt-6">
      {
         links.map(link => {
            return <a 
               key={link.href}
               href={link.href}
               className="text-gray-600 inline-block mx-3"
            >
               <link.icon />
            </a>
         })
      }
   </div>
}

function Copyright() {
   return <div className="text-center text-sm text-gray-500">
      &copy; {new Date().getFullYear()} Setget
   </div>
}

function Links() {

   const links = [
      {
         to: '/signup',
         caption: 'Sign up'
      },
      {
         to: '/documentation',
         caption: 'Docs'
      },
      {
         to: '/downloads',
         caption: 'Downloads'
      }
   ]

   return <div>
      {
         links.map(link => {
            return <Link
               key={link.caption}
               to={link.to}
               className="inline-block mx-6 text-center no-underline text-[var(--accent-2)] font-bold text-sm"
            >
               {link.caption}
            </Link>
         })
      }
   </div>
}

function Xavisoft() {
   return <div className="text-right pr-6 text-sm text-gray-500">
      Made by <a 
         href="https://xavisoft.co.zw"
         className="no-underline text-purple-800 font-bold"
      >Xavisoft Digital</a>
   </div>
}


function AllLinks() {

   return <div className="grid grid-cols-1 md:grid-cols-2">
      <div className="h-full vh-align">
         <Links />
      </div>
      <div className="h-full vh-align">
         <Socials />
      </div>
   </div>
}

function FooterUnconnected(props) {

   if (window.cordova)
      return;

   if (props.currentRoute === '/dashboard')
      return;

   return <footer>
      <div 
         className="py-7 bg-gradient-to-br from-purple-300 to-white" 
      >
         <AllLinks />
         <Copyright />
         <Divider className="my-4" />
         <Xavisoft />
         </div>
   </footer>
}

function mapStateToProps(state) {
   const { currentRoute } = state;
   return { currentRoute }
}

const Footer = connect(mapStateToProps)(FooterUnconnected)
export default Footer