import { combineReducers } from "redux";
import currentRouteReducer from "./currentRouteReducer";
import authenticatedReducer from "./authenticatedReducer";
import sensorHistoryReducer from "./sensorHistoryReducer";
import entitiesReducer from "./entitiesReducer";
import rootCertificateReducer from './rootCertificateReducer';
import previousRouteReducer from './previousRouteReducer';


const reducer = combineReducers({
   authenticated: authenticatedReducer,
   currentRoute: currentRouteReducer,
   previousRoute: previousRouteReducer,
   sensorHistory: sensorHistoryReducer,
   entities: entitiesReducer,
   rootCertificate: rootCertificateReducer,
});

export default reducer;